import { Component, EventEmitter, Input, Output, input } from '@angular/core';
import { AccordionItemComponent } from '../../../accordions/accordion-item/accordion-item.component';
import { ListPageHeaderComponent } from '../../list-page-header/list-page-header.component';
import { BasicTableComponent } from '../../../tables/basic-table/basic-table.component';
import {
  PaginationConfig,
  TableColumn,
} from '../../../../../core/modals/table.modal';

@Component({
  selector: 'app-associated-item',
  standalone: true,
  imports: [
    AccordionItemComponent,
    ListPageHeaderComponent,
    BasicTableComponent,
  ],
  templateUrl: './associated-item.component.html',
  styleUrl: './associated-item.component.scss',
})
export class AssociatedItemComponent {
  @Input() id: string;
  @Input() title: string;
  @Input() total: number;
  @Input() isRawSpan: boolean = false;
  @Input() coloums: TableColumn[];
  @Input() items: any[] = [];
  @Input() pageConf: PaginationConfig;

  @Output() searchRequest = new EventEmitter<string>();
  @Output() pageChangeRequest = new EventEmitter();

  constructor() {}

  requestPageChange() {
    this.pageChangeRequest.emit();
  }

  requestSearch($event: string | undefined) {
    this.searchRequest.emit($event);
  }
}
